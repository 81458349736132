import React from 'react'
import {Carousel} from 'react-responsive-carousel'
import {getResizedImageSSR_image} from '@data/queries/components/__generated__/getResizedImageSSR'
import isServerSide from '@helpers/misc/isServerSide'
import Container from '@packages/justo-parts/lib/components/Container'
import autobind from 'autobind-decorator'
import {NextRouter, withRouter} from 'next/router'

import Slide from './Slide'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styles from './styles.module.css'

export interface SliderProps {
  router: NextRouter
  slides: any[]
  title: any
  fullWidth: any
  duration: any
  ssrImages?: getResizedImageSSR_image[]
}

export class Slider extends React.Component<SliderProps> {
  static defaultProps = {
    slides: [],
    ssrImages: []
  }

  @autobind
  onClickItem(index) {
    const {link, blankLinkTarget} = this.props.slides[index]
    if (!link) return
    if (isServerSide()) return

    if (blankLinkTarget) {
      window.open(link)
    } else {
      if (link.startsWith('/')) {
        this.props.router.push(link)
      } else {
        window.location.href = link
      }
    }
  }

  renderSlide(slide, index) {
    return <Slide {...slide} key={index} ssrImage={this.props.ssrImages[index]} />
  }

  render() {
    const content = (
      <div className={styles.container}>
        {this.props.title ? <div className={styles.title}>{this.props.title}</div> : null}
        <Carousel
          emulateTouch
          showThumbs={false}
          showStatus={false}
          interval={this.props.duration ? this.props.duration : 3000}
          autoPlay
          stopOnHover
          infiniteLoop
          useKeyboardArrows
          onClickItem={this.onClickItem}>
          {this.props.slides.map((slide, index) => this.renderSlide(slide, index))}
        </Carousel>
      </div>
    )

    if (this.props.fullWidth) return content
    return <Container>{content}</Container>
  }
}

export default withRouter<SliderProps>(Slider)
