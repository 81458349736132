import React from 'react'
import Loading from '@components/SuspenseLoading/Loading'
import isServerSide from '@helpers/misc/isServerSide'
import useResizedImageURL from '@hooks/useResizedImageURL'
import useWidth from '@hooks/useWidth'

import styles from './styles.module.css'

export default React.memo<{
  backgroundImage: any
  backgroundImageMobile?: any
  title?: string
  ssrImage?: {resizedURL: string}
}>(function Slide({backgroundImage, backgroundImageMobile, title, ssrImage}) {
  const width = useWidth()
  const isSmall = width <= 768
  const file = isSmall ? backgroundImageMobile || backgroundImage : backgroundImage

  const url = useResizedImageURL({fileId: file._id, width: isSmall ? 400 : 1600}, {partial: true})

  const finalUrl = url ? url : ssrImage?.resizedURL
  if (isServerSide() && !ssrImage?.resizedURL) {
    console.warn(
      'Could not fetch slider image while doing SSR. This should be fixed for an optimized experience'
    )
    return <Loading />
  }
  if (!finalUrl) return <Loading />

  return (
    <div className={styles.container}>
      <img src={finalUrl} alt={title || ''} title={title || ''} />
    </div>
  )
})
